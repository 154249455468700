import dayjs from 'dayjs'
import { useEffect } from 'react'

const BannerList = ({showModal, handleShow, bannerList, setSelectedBannerId}) => {

    const openEditModal = (id) => {
        setSelectedBannerId(id)
        handleShow('Edit')
    }

    useEffect(() => {
        if (!showModal) {
            setSelectedBannerId(0)
        }
    }, [showModal])

  return (
    <div className="banner-list-container my-5">
        <div className="table-responsive">
            <table className="table">
                <thead className="thead-light">
                <tr>
                    <th scope="col">Banner Name</th>
                    <th scope="col">Upload Date</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Preview</th>
                    <th scope="col">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                    {bannerList.length > 0 ? 
                        bannerList.map((banner) => (
                            <tr key={banner.id}>
                                <th scope="row">{banner.bannerName}</th>
                                <td>{banner.uploadDate && dayjs(banner.uploadDate).format('MM/DD/YYYY')}</td>
                                <td>{banner.startDate && dayjs(banner.startDate).format('MM/DD/YYYY')}</td>
                                <td>{banner.endDate && dayjs(banner.endDate).format('MM/DD/YYYY')}</td>
                                <td><img src={`${process.env.REACT_APP_BASE_URL}/MicroReferBanner/${banner.fileName}`} style={{width:125, height:75, objectFit: 'cover'}} className="img-fluid" alt={banner.BannerName} title={banner.bannerName} /></td>
                                <td>
                                    <a href="#" onClick={() => openEditModal(banner.id)}>Edit</a>
                                </td>
                            </tr>
                        ))
                     : null}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default BannerList