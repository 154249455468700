import Switch from 'react-switch'
import useToastNotification from '../../hooks/useToastNotification'
import useCurrency from '../../hooks/useCurrency'

const HotJobList = ({hotJobs, setHotJobs, switchHotJob}) => {

    const { notifySuccess, notifyError, notifyPromise } = useToastNotification()

    const currencyFormat = useCurrency()

    const handleSwitch = (obj) => {
        obj.hotJob = !obj.hotJob

        notifyPromise(switchHotJob(obj).then(res => {
            if (res.status === 200) {

                const currentState = hotJobs.map(hot => {
                    if (hot.id === obj.id) {
                        return {...hot, hotJob: obj.hotJob}
                    }
        
                    return hot
                })
        
                setHotJobs(currentState)
      
            } else {
              console.log(`Error ${res.status}: ${res.statusText}`)
            }
          }), 'Updating Hot Job...', 'Updated Successfully', 'Something went wrong')
    }

  return (
    <div className="banner-list-container my-5">
        <div className="table-responsive">
            <table className="table">
                <thead className="thead-light">
                <tr>
                    <th scope="col">Job Title</th>
                    <th scope="col">Category</th>
                    <th scope="col">Incentive</th>
                    <th scope="col">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                    {hotJobs.length > 0 ? 
                        hotJobs.map((hot, index) => (
                            <tr key={index}>
                                <th scope="col">{hot.jobTitle}</th>
                                <th scope="col">{hot.category}</th>
                                <th scope="col">{currencyFormat(hot.incentive)}</th>
                                <td>
                                    <Switch onChange={() => handleSwitch(hot)} checked={hot.hotJob} onColor="#dc7227"/>
                                </td>
                            </tr>
                        ))
                     : null}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default HotJobList