import { useState } from 'react'
import FaqAccordion from './FaqAccordion'
import { SignInSlider } from '../components/Carousel/Slider'
import Login from '../components/SignIn/Login'

const SignIn = () => {

    document.title = 'MicroRefer'

  return (
    <main id="home">
        <section className="section-banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <h1 className="mb-5">You Deserve the <strong>EXTRA</strong> in the Ordinary</h1>
                    </div>
                    <div className="col-md-5">
                        <div className="form-container">
                            <h3>Welcome to MicroRefer</h3>
                            <Login/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <SignInSlider/>

        <section id="section-faq" className="bg-blue" style={{padding:'120px 0'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <h2 className="section-title text-center">Frequently Asked Questions</h2>
                        <FaqAccordion/>
                    </div>
                </div>
            </div>
        </section>
    </main>
  )
}

export default SignIn