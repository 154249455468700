import { Navigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../context/AuthProvider'

const hasLogin = (user) => {
  return Object.keys(user).length > 0

}

// Checks if already logged in
// Has login - redirect to Home
// Has no login - show login component
export const LoginRoute = ({ children }) => {
  const { authUser } = useContext(AuthContext)
  
  return hasLogin(authUser) ? <Navigate to='/Home'/> : children

}


// Checks if already logged in and is Admin component authorize
// Has login, then has admin authorization - redirect to <Admin/> component
// Has login, then has no admin authorization - redirect to <Home/> component
// Has no login, then restrict back to <SignIn/> component
export const AdminAuthorizationRoute = ({ children }) => {
  const { authUser } = useContext(AuthContext)

  if (hasLogin(authUser)) {

    const hasAdminRole = authUser.roles.filter((role) => role === 'MicroReferAdmin')

    return hasAdminRole.length === 1 ? children : <Navigate to='/Home'/>

  } else {
    return <Navigate to='/'/>
    
  }
}


// Checks if logged in
// has login - return protected routes
// has no login - redirect to <SignIn/> component
export const PrivateRoute = ({children}) => {
  const { authUser } = useContext(AuthContext)

  return hasLogin(authUser) ?  children : <Navigate to='/'/>

}