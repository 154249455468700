import { useEffect, useState } from 'react'

const ManageHotJobs = ({jobCategoryList, setCategoryFilter, setTitleFilter, getHotJobListTotalRows}) => {

  const [dropdownFilter, setDropdownFilter] = useState([])

  useEffect(() => {
      const res = jobCategoryList.filter(job => job.categoryName !== 'Hot Jobs')
      setDropdownFilter(res)

  }, [jobCategoryList])


  return (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
        <h3 className="mb-4 mb-md-0">Manage Hot Jobs</h3>

        <div className="dropdown-group d-flex flex-column flex-md-row justify-content-between align-items-md-center">
            <div className="mr-3 mb-3 mb-md-0">
                <select className="custom-select" onChange={(e) => setCategoryFilter(e.target.value)}>
                  <option key={0} value="">Show All</option>
                  {
                    dropdownFilter.map((categ, index) => <option key={index + 1} value={categ.categoryName}>{categ.categoryName}</option>)
                  }
                </select>
            </div>
            <div className="mr-3 mb-3 mb-md-0">
              <input type="search" className="form-control" placeholder="Search job title" maxLength="50" aria-label="Search Job" aria-describedby="basic-search-name"
                  onChange={(e) => setTitleFilter(e.target.value)}
                  onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        getHotJobListTotalRows()
                      }
                  }}
              />
            </div>
        </div>
    </div>
  )
}

export default ManageHotJobs