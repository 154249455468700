const ManageBanner = ({handleShow, setDisplayRowDropDown}) => {
    return (
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
          <h3 className="mb-4 mb-md-0">Manage Banners</h3>
  
          <div className="dropdown-group d-flex flex-column flex-md-row justify-content-between align-items-md-center">
              <div className="mr-3 mb-3 mb-md-0">
                  <select className="custom-select" onChange={(e) => setDisplayRowDropDown(e.target.value)}>
                      <option value="false">Show All</option>
                      <option value="true">Active</option>
                  </select>
              </div>
              {/* <div className="mr-3 mb-3 mb-md-0">
                  <select className="custom-select">
                      <option value="SortBy">Sort by</option>
                      <option value="Date">Date</option>
                      <option value="Name">Name</option>
                  </select>
              </div> */}
              <div className="mr-3 mb-3 mb-md-0">
                  <button className="btn button btn-outline font-weight-normal w-100" onClick={() => handleShow('Add')}>
                      <i className="fas fa-plus-circle mr-1"></i> 
                      Add Banner
                  </button>
              </div>
          </div>
      </div>
    )
  }
  
  export default ManageBanner