import 'react-toastify/dist/ReactToastify.css'
import 'axios-progress-bar/dist/nprogress.css'
import './progress-bar.css'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { AuthProvider } from './context/AuthProvider'
import { ToastProvider } from './context/ToastProvider'
import Footer from './pages/Footer'
import Navbar from './pages/Navbar'
import Home from './pages/Home'
import MyReferrals from './pages/MyReferrals'
import Faq from './pages/Faq'
import Admin from './pages/Admin'
import SignIn from './pages/SignIn'
import NotFound from './pages/NotFound'
import JobDetails from './pages/JobDetails'
import HotJobs from './pages/HotJobs'
import { PrivateRoute, LoginRoute, AdminAuthorizationRoute } from './components/PrivateRoute'
import $ from 'jquery'

$(window).on('scroll',function () {
  
  if ($(this).scrollTop() > 500) {
      $('.back_to_top').fadeIn();

  } else {
      $('.back_to_top').fadeOut();

  }

});

const ScrollToTop = (props) => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <>{props.children}</>
}

function App() {

  return (
    <BrowserRouter>
      <ToastProvider>
        <AuthProvider>
          <Navbar/>
            <ScrollToTop>
                <Routes>
                  <Route path="/" element={<LoginRoute><SignIn/></LoginRoute>}/>
                  <Route path="/Home" element={<PrivateRoute><Home/></PrivateRoute>}/>
                  <Route path="/JobDetails/:jobId" element={<JobDetails/>}/> {/* No Auth */}
                  <Route path="/MyReferrals" element={<PrivateRoute><MyReferrals/></PrivateRoute>}/>
                  <Route path="/Faq" element={<PrivateRoute><Faq/></PrivateRoute>}/>
                  <Route path="/Admin" element={<AdminAuthorizationRoute><Admin/></AdminAuthorizationRoute>}/>
                  <Route path="/HotJobs" element={<AdminAuthorizationRoute><HotJobs/></AdminAuthorizationRoute>}/>
                  <Route path="*" element={<NotFound/>}/>
                </Routes>              
            </ScrollToTop>
          <Footer/>
        </AuthProvider>
      </ToastProvider>
    </BrowserRouter>
  )
}

export default App