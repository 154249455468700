import JobDescription from '../components/JobDetails/JobDescription'
import ReferForm from '../components/JobDetails/ReferForm'
import { JobDetailsTitle } from './SectionTitle'
import QuestionAbout from './QuestionAbout'
import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthProvider'
import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'

/**
 * In <Navbar/> Component, Login button will show if the user is not authenticated
 * The button has a state value that set the current location specific value will be /jobdetails/.
 * If the login button in navbar is clicked, it will redirect to login page, then back to this page
 * after logging in
 */

const JobDetails = () => {

    document.title = 'Job Details | MicroRefer'

    loadProgressBar()
    
    const { authUser } = useContext(AuthContext)

    const { jobId } = useParams()

    const [jobDetails, setJobDetails] = useState({})

    const [showNoResult, setShowNoResult] = useState(false)

    useEffect(() => {
        
        setShowNoResult(prev => prev = false)

        const getJobDetails = async () => {
          const responseJobDetailsData = await axios.get(`${process.env.REACT_APP_BASE_URL}/microrefer/getjobdetails/${jobId}`)
          setJobDetails(responseJobDetailsData.data)

          if (responseJobDetailsData.data.id === 0) {
            setShowNoResult(prev => prev = true)
        } else {
            setShowNoResult(prev => prev = false)
        }
        }
    
        getJobDetails()
      }, [])


  return (
    <main id="job-page">

        {
            jobDetails.id !== 0 ? (
                <>
                    <JobDetailsTitle jobDetails={jobDetails}/>

                    <section id="section-job" className="mt-3 mt-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 pr-0 pr-md-5">
                                        <JobDescription jobDetails={jobDetails}/>
                                    </div>
                                    {
                                        Object.keys(authUser).length > 0 ? (
                                            
                                            <div className="col-lg-4">
                                                <div className="refer-a-friend">
                                                    <h2 className="font-weight-normal text-uppercase">Refer a friend!</h2>
                                                    <ReferForm jobId={jobId}/>
                                                </div>
                                            </div>
                                            

                                        ) : null
                                    }
                                </div>
                            </div>
                    </section>
                </>
            ) : null
        }

        {
            showNoResult ? (
                <>
                    <section id="section-job" className="mt-3 mt-md-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 pr-0 pr-md-5">
                                    <div style={{marginTop: '150px'}}>
                                        <h1>No Results Found</h1>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : null
        }

        <QuestionAbout/>
    </main>
  )
}

export default JobDetails