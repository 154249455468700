import { useState, useEffect } from 'react'
import AdminModal from '../components/Admin/AdminModal'
import SectionTitle from './SectionTitle'
import ManageBanner from '../components/Admin/ManageBanner'
import BannerList from '../components/Admin/BannerList'
import Paging from '../components/Paging'
import useAxios from '../hooks/useAxios'
import useToastNotification from '../hooks/useToastNotification'

const Admin = () => {

  document.title = 'Manage Banner | MicroRefer'

  const api = useAxios()
  const { notifySuccess, notifyError, notifyPromise } = useToastNotification()

  // List & Paging State
  const [showModal, setShowModal] = useState(false)
  const [bannerList, setBannerList] = useState([])

  const [displayRowDropDown, setDisplayRowDropDown] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [updatedTheListCount, setUpdatedTheListCount] = useState(0)

  const getBannerListTotalRows = async () => {
    const responseTotalRows = await api.get(`/microrefer/microreferbannertotalrows?bannername=&active=${displayRowDropDown}`)
    setTotalRows(responseTotalRows.data)

    getBannerList(1)
  }

  const getBannerList = async (page) => {
    // default page size is 10
    const responsePaginatedList = await api.get(`/microrefer/paginatedmicroreferbanner?pageIndex=${page}&pageSize=${10}`)
    setBannerList(responsePaginatedList.data)
  }

  useEffect(() => {
    getBannerListTotalRows()

  }, [displayRowDropDown, updatedTheListCount])

  const changePage = ({ selected }) => {
    const currentPage = selected + 1

    getBannerList(currentPage)
  }

  // CRUD State
  const [crudState, setCrudState] = useState('')
  const [selectedBannerId, setSelectedBannerId] = useState(0)


  // Modal State
  const handleShow = (modalState) => {
    setCrudState(modalState)
    setShowModal(true)

  }

  const handleClose = () => {
    setCrudState('')
    setShowModal(false)
  }

  // Add Banner
  const addBanner = async (banner) => {

    const fileUpload = banner.crudFile

    const formData = new FormData()
    formData.append('file', fileUpload)

    const fileUploadResponse = await api.post('/microrefer/uploadmicroreferbanner', formData)

    if (fileUploadResponse.status === 201 || fileUploadResponse.status === 200) {
      const microReferDto = {
        FileName: banner.crudFile.name,
        StartDate: banner.crudStartDate,
        EndDate: banner.crudEndDate,
        Url: banner.crudBannerUrl,
        BannerName: banner.crudBannerName
      }

      const addBannerResponse = await api.post('/microrefer/addmicroreferbanner', JSON.stringify(microReferDto), {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      return addBannerResponse

    } else {
      return fileUploadResponse

    }

  }

  // Update Banner
  const updateBanner = async (banner) => {
    const microReferDto = {
      Id: banner.selectedBannerId,
      FileName: banner.crudFileName,
      StartDate: banner.crudStartDate,
      EndDate: banner.crudEndDate,
      Url: banner.crudBannerUrl,
      BannerName: banner.crudBannerName
    }

    const updateBannerResponse = await api.post('/microrefer/updatemicroreferbanner', JSON.stringify(microReferDto), {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    return updateBannerResponse

  }

  // Delete Banner
  const deleteBanner = async () => {
    const deleteResponse = await api.post(`/microrefer/removemicroreferbanner/${selectedBannerId}`)

    return deleteResponse
  }

  return (
    <main id="admin-page">
      <SectionTitle title="Admin" />

      <section id="section-manage-banners">
        <div className="container">
          <ManageBanner handleShow={handleShow} displayRowDropDown={displayRowDropDown} setDisplayRowDropDown={setDisplayRowDropDown} />
          <BannerList showModal={showModal} handleShow={handleShow} bannerList={bannerList} setSelectedBannerId={setSelectedBannerId} />
          <Paging totalRows={totalRows} changePage={changePage} pageSize={10} />
        </div>
      </section>

      <AdminModal
        crudState={crudState}
        showModal={showModal}
        handleClose={handleClose}
        addBanner={addBanner}
        updateBanner={updateBanner}
        deleteBanner={deleteBanner}
        selectedBannerId={selectedBannerId}
        setUpdatedTheListCount={setUpdatedTheListCount}
      />
    </main>
  )
}

export default Admin