import { useState, useEffect } from 'react'
import useAxios from '../hooks/useAxios'
import SectionTitle from './SectionTitle'
import ManageHotJobs from '../components/HotJobs/ManageHotJobs'
import HotJobList from '../components/HotJobs/HotJobList'
import Paging from '../components/Paging'

const HotJobs = () => {

  document.title = 'Manage Hot Jobs | MicroRefer'
    
  const api = useAxios()



  // category filter container
  const [jobCategoryList, setJobCategoryList] = useState([])

  // search filters
  const [categoryFilter, setCategoryFilter] = useState('')
  const [titleFilter, setTitleFilter] = useState('')
  const [showNoResult, setShowNoResult] = useState(false)

  // paging
  const [totalRows, setTotalRows] = useState(0)
  const [hotJobs, setHotJobs] = useState([])


  // get total IDs
  const getHotJobListTotalRows = async () => {
    const responseTotalRows = await api.get(`/microrefer/jobroletotalrows?title=${titleFilter}&category=${categoryFilter}`)
    setTotalRows(responseTotalRows.data) 
    
    getHotJobList(1)
  }

  // get paging list
  const getHotJobList = async (page) => {

    setShowNoResult(prev => prev = false)

    
    // default page size is 10
    const responsePaginatedList = await api.get(`/microrefer/paginatedjobrolelist?pageIndex=${page}&pageSize=${10}`)
    setHotJobs(responsePaginatedList.data)

    if (responsePaginatedList.data.length > 0) {
      setShowNoResult(prev => prev = false)
  } else {
      setShowNoResult(prev => prev = true)
  }
  }

  // on init get job categories for dropdown category filter
  useEffect(() => {
    const getJobCategories = async () => {
      const responseJobCategoryList = await api.get(`/microrefer/getjobopeningscategory?location=`)
      setJobCategoryList(responseJobCategoryList.data)
    }

    getJobCategories()

  }, [])

  // on init get hot job total IDs
  // and every category filter change
  useEffect(() => {
    getHotJobListTotalRows()

  }, [categoryFilter])

  const changePage = ({ selected }) => {
    const currentPage = selected + 1
    
    getHotJobList(currentPage)
  }

  // switch hotjob
  const switchHotJob = async (obj) => {
    const switchHotJobResponse = await api.post(`/microrefer/setjobroleashotjob?id=${obj.id}&hotjob=${obj.hotJob}`)

    return switchHotJobResponse    
  }

  return (
    <main id="admin-page">
        <SectionTitle title="Hot Jobs"/>

        <section id="section-manage-banners">
            <div className="container">
                <ManageHotJobs
                  jobCategoryList={jobCategoryList}
                  setCategoryFilter={setCategoryFilter} 
                  setTitleFilter={setTitleFilter} 
                  getHotJobListTotalRows={getHotJobListTotalRows}
                />

                {
                  hotJobs.length > 0 ? (
                    <>
                      <HotJobList hotJobs={hotJobs} setHotJobs={setHotJobs} switchHotJob={switchHotJob}/>
                      <Paging totalRows={totalRows} changePage={changePage} pageSize={10}/>
                    </>
                  ) : null
                }

                {
                  showNoResult ? <><br/><br/><h3>No Results Found</h3><br/><br/></> : null
                }
            </div>          
        </section>
    </main>
  )
}

export default HotJobs