import { useState, useContext } from 'react'
import useToastNotification from '../../hooks/useToastNotification'
import useAxios from '../../hooks/useAxios'
import { AuthContext } from '../../context/AuthProvider'
import { useNavigate } from 'react-router-dom'

const ReferForm = ({jobId}) => {

    const { authUser } = useContext(AuthContext)
    const navigate = useNavigate()
    
    const api = useAxios()

    const { notifyPromise, notifySuccess, notifyError } = useToastNotification()

    const [crudFirstName, setCrudFirstName] = useState('')
    const [crudLastName, setCrudLastName] = useState('')
    const [crudEmail, setCrudEmail] = useState('')
    const [crudContactNumber, setCrudContactNumber] = useState('')
    const [crudOtherContactNumber, setOtherCrudContactNumber] = useState('')
    const [crudFile, setCrudFile] = useState('')

    const [crudCheckBox, setCrudCheckBox] = useState(true)

    const validateFields = () => {
        let errors = [];

        if (!crudFirstName) {
            errors.push('First Name is required')
        }

        if (!crudLastName) {
            errors.push('Last Name is required')
        }

        if (!crudEmail) {
            errors.push('Email is required')
        } else {
            // regex pattern for email

            const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

            if (!emailPattern.test(crudEmail)) {
                errors.push('You have entered an invalid email address')
            }
        }

        if (!crudContactNumber) {
            errors.push('Contact Number is required')
        }
        
        if (!crudFile) {
            errors.push('Upload Resume is required')

        } else {
            const fileExts = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

            if (fileExts.filter(ext => crudFile.type === ext).length === 0) {
                errors.push('Invalid Upload Resume file extension')
            }

            if (crudFile.name.length > 150) {
                errors.push('File Name exceeds 150 characters')
            }

            if (crudFile.size > 3000000) {
                errors.push('File exceeds 3MB')
            }
        }
        
        return errors
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const errorMessages = validateFields()

        if (errorMessages.length > 0) {
            errorMessages.forEach(err => notifyError(err))           

        } else {

            const newReferralDto = {
                FirstName: crudFirstName,
                LastName: crudLastName,
                Email: crudEmail,
                Mobile: crudContactNumber,
                Phone: crudOtherContactNumber,
                EM_ID: authUser.eM_ID,
                JobId: jobId
            }

            //Checks the email first
            notifyPromise(api.get(`/microrefer/isemailexists?email=${newReferralDto.Email}`).then(res => {
                if(res.status === 200) {

                    // true means already exist
                    if (res.data) {
                        notifyError('Candidates email ID is already available in the system and cannot be re-referred')
                    } else {

                        // Add New Referral
                        notifyPromise(api.post('/microrefer/newreferral', JSON.stringify(newReferralDto), {
                            headers:{
                              'Content-Type':'application/json'
                            }
            
                        }).then(response => {
            
                            if (response.status === 200 || 201) {
            
                                const formData = new FormData()
                                formData.append('file', crudFile)
                                formData.append('id', response.data)
                                
                                // New Referral endpoint returns candidate Id, then pass it to upload resume
                                notifyPromise(api.post('/microrefer/uploadresumefile', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(fileResponse => {
                                    if (fileResponse.status === 200 || 201) {
                                        // Redirect to MyReferrals
                                        navigate('/MyReferrals')

                                    } else {
                                        console.log(`Error ${fileResponse.status}: ${fileResponse.statusText}`)
                                    }
                                }), 'Uploading Resume...', 'Upload Successfully', 'Something went wrong')
            
                            } else {
                                console.log(`Error ${response.status}: ${response.statusText}`)
                            }
            
                        }), 'Referring a friend...', 'Referred Successfully', 'Something went wrong')

                    }
                    
                } else {
                    notifyError('Something went wrong')
                    console.log(`Error ${res.status}: ${res.statusText}`)
                }
            }), 'Checking Email...', null, null)
            
        }
    }

  return (
    <form autoComplete="off">
        <div className="form-group">
            <label htmlFor="fieldNameFirst" className="col-form-label">First Name</label>
            <input type="text" className="form-control" id="fieldNameFirst" onChange={(e) => setCrudFirstName(e.target.value)} maxLength="50"/>
        </div>
        <div className="form-group">
            <label htmlFor="fieldNameLast" className="col-form-label">Last Name</label>
            <input type="text" className="form-control" id="fieldNameLast" onChange={(e) => setCrudLastName(e.target.value)} maxLength="50"/>
        </div>
        <div className="form-group">
            <label htmlFor="fieldEmail" className="col-form-label">Email</label>
            <input type="email" className="form-control" id="fieldEmail" onChange={(e) => setCrudEmail(e.target.value)} maxLength="50"/>
        </div>
        <div className="form-group">
            <label htmlFor="fieldContactNumber" className="col-form-label">Contact Number</label>
            <input type="tel" className="form-control" id="fieldContactNumber" maxLength="11" 
            onChange={(e) => setCrudContactNumber(e.target.value)} 
            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9.]/g, '')}/>
        </div>
        <div className="form-group">
            <label htmlFor="fieldContactNumberOther" className="col-form-label">Other Contact Number</label>
            <input type="tel" className="form-control" id="fieldContactNumberOther" maxLength="11" 
            onChange={(e) => setOtherCrudContactNumber(e.target.value)}
            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9.]/g, '')}/>
        </div>
        <div className="form-group">              
            <label htmlFor="fileUpload" className="col-form-label">Upload Resume</label>
            <input type="file" className="form-control form-control-sm" id="fileUpload" size="sm" onChange={(e) => setCrudFile(e.target.files[0])}/>
            <small id="inputFileHelp" className="form-text text-muted">
                <ul style={{paddingLeft: '30px', fontSize: '10px'}}>
                    <li>File Name must below 150 characters</li>
                    <li>Max File size of 3MB</li>
                    <li>File must be a PDF or Word</li>
                </ul>
            </small>
        </div>
        <br/>
        <div className="form-group custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="customCheck1" onChange={() => setCrudCheckBox(!crudCheckBox)}/>
            <label className="custom-control-label consent-check" htmlFor="customCheck1">
                In providing these details to MicroSourcing, you hereby certify that you have obtained full and indisputable 
                consent from the referred applicant to submit their personal information for the purpose of establishing initial 
                contact and screening qualifications. The personal information provided shall be kept for a period of 3 years in 
                our database and 6 months in our active pool data.
            </label>
        </div>
        <button className="btn button" disabled={crudCheckBox} onClick={(e) => onSubmit(e)}>Submit</button>
    </form>
  )
}

export default ReferForm