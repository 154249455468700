import { useState } from 'react'
import Slider from 'react-slick'

export const SignInSlider = () => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const sliderSettings = {
        signInSliderSettingsParent: {
            className: 'home-slider-for',
            asNavFor: nav2,
            ref: (slider1) => setNav1(slider1),
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover:true,
            infinite: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false
                    }
                }
            ]
        },
        signInSliderSettingsChild: {
            className: 'home-slider-nav',
            asNavFor: nav1,
            ref: (slider2) => setNav2(slider2),
            slidesToShow: 4,
            slidesToScroll: 1,
            swipeToSlide: true,
            centerMode: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        }
    };

  return (
    <section id="section-work-life-balance">
      <div className="container">
          <Slider {...sliderSettings.signInSliderSettingsParent}>
              <div className="slide-item">
                  <div className="row">
                      <div className="col-md-6">
                          <h6 className="section-sub-title">How it works</h6>
                          <h2 className="section-title">Get Started!</h2>
                          <p>Login using your MicroStatus credentials. Requesting additional text here to balance the layout. Lorem ipsum dolor amet.</p>
                      </div>
                      <div className="col-md-6">
                          <img src="/refer/images/sliding-temp-image1.png" className="img-fluid" alt="Get Started!"/>
                      </div>
                  </div>
              </div>
              <div className="slide-item">
                  <div className="row">
                      <div className="col-md-6">
                          <h6 className="section-sub-title">How it works</h6>
                          <h2 className="section-title">Add your referrals</h2>
                          <ul>
                              <li>On My Referral tab on top of the page and select Add Referral.</li>
                              <li>Fill in the necessary details and select the desired position of your referral.</li>
                              <li>Attach your referral’s updated resume.</li>
                              <li>Tick on the privacy agreement box and click Submit.</li>
                          </ul>
                      </div>
                      <div className="col-md-6">
                          <img src="/refer/images/sliding-temp-image2.png" className="img-fluid" alt="Work-life Balance"/>
                      </div>
                  </div>
              </div>
              <div className="slide-item">
                  <div className="row">
                      <div className="col-md-6">
                          <h6 className="section-sub-title">How it works</h6>
                          <h2 className="section-title">Referral Confirmation</h2>
                          <ul>
                              <li>Make sure the submission was successful.  A confirmation will be sent to your email address when you successfully refer someone.</li>
                              <li>Your referral will be notified via email about the application and the proceeding process.</li>
                          </ul>
                      </div>
                      <div className="col-md-6">
                          <img src="/refer/images/sliding-temp-image3.png" className="img-fluid" alt="Opportunities for growth"/>
                      </div>
                  </div>
              </div>
              <div className="slide-item">
                  <div className="row">
                      <div className="col-md-6">
                          <h6 className="section-sub-title">How it works</h6>
                          <h2 className="section-title">Be Rewarded!</h2>
                          <p>Receive referral incentive that comes with the role when your referral gets hired. Go to My Referral to check the status of the application of your referral, referral amount, and payout dates.</p>
                      </div>
                      <div className="col-md-6">
                          <img src="/refer/images/sliding-temp-image4.png" className="img-fluid" alt="Opportunities to give back"/>
                      </div>
                  </div>
              </div>
          </Slider>

          <Slider {...sliderSettings.signInSliderSettingsChild}>
              <div><h3>Get Started!</h3></div>
              <div><h3>Add your referrals</h3></div>
              <div><h3>Referral confimation</h3></div>
              <div><h3>Be Rewarded!</h3></div>
          </Slider>
      </div>
    </section>
  )
}

export const HomeSlider = ({slideList}) => {
    const sliderSettings = {
        slidesToShow: 1,
        arrows: true,
        speed: 500,
        dots: false,
        centerMode: true,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        infinite: true,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                variableWidth: false
            }
            }
        ]
    };

  return (
     <section className="section-banner">
            <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col-12">
                        {
                            slideList.length > 0 ? (
                                <Slider {...sliderSettings}>
                                    {
                                        slideList.map(slide => 
                                            <div key={slide.id}>
                                                <img 
                                                    src={`${process.env.REACT_APP_BASE_URL}${slide.imageSource}`} 
                                                    className="img-fluid" 
                                                    alt={`${slide.Url}`} 
                                                    style={{width:920, height: 550, objectFit: 'cover', cursor: 'pointer'}}
                                                    onClick={() => {
                                                        if (slide.url) {
                                                            window.open(`${slide.url}`, '_blank')
                                                        }
                                                    }}
                                                />
                                            </div>)
                                    }
                                </Slider>
                            ) : null
                        }

                    </div>
                </div>
            </div>
        </section>
    )
  }