import QuestionAbout from './QuestionAbout'
import SectionTitle from './SectionTitle'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../context/AuthProvider'
import useAxios from '../hooks/useAxios'
import Paging from '../components/Paging'
import useToastNotification from '../hooks/useToastNotification'
import useCurrency from '../hooks/useCurrency'

const MyReferrals = () => {

    document.title = 'My Referrals | MicroRefer'

    const { authUser } = useContext(AuthContext)

    const { notifyPromise, notifySuccess, notifyError } = useToastNotification()

    const api = useAxios()

    const currencyFormat = useCurrency()

    // search filter state
    const [statusFilter, setStatusFilter] = useState('')
    const [nameFilter, setNameFilter] = useState('')
    const [totalRows, setTotalRows] = useState(0)
    const [referralList, setReferralList] = useState([])
    const [showNoResult, setShowNoResult] = useState(false)
    const [hotJobList, setHotJobList] = useState([])

    // use this only for re-uploading resume
    const [selectedPage, setSelectedPage] = useState(1)

    const getMyReferralTotalRows = async () => {
        const responseTotalRows = await api.get(`/microrefer/getmyreferraltotalrows?emid=${authUser.eM_ID}&name=${nameFilter}&hired=${statusFilter}`)
        setTotalRows(responseTotalRows.data)

        getMyReferralList(1)
    }

    const getMyReferralList = async (page) => {

        setShowNoResult(prev => prev = false)

        // default page size is 3
        const responsePaginatedList = await api.get(`/microrefer/paginatedmyreferrallist?pageIndex=${page}&pageSize=${3}`)

        // add new object property name file for containing re-upload resume
        const newState = responsePaginatedList.data.map(data => {
            return { ...data, reUploadFile: '' }
        })

        setReferralList(newState)

        if (responsePaginatedList.data.length > 0) {
            setShowNoResult(prev => prev = false)
        } else {
            setShowNoResult(prev => prev = true)
        }
    }

    useEffect(() => {
        // fetch new total rows if dropdown filter changes
        getMyReferralTotalRows()

    }, [statusFilter])

    const changePage = ({ selected }) => {
        const currentPage = selected + 1;
        setSelectedPage(prev => prev = currentPage)
        getMyReferralList(currentPage)
    }

    // update contacts
    const updateContacts = (referralObject) => {

        const errorMessages = validateSaveChanges(referralObject)

        if (errorMessages.length > 0) {
            errorMessages.forEach(err => notifyError(err))
        } else {
            const updateContactDto = {
                Id: referralObject.candidateID,
                Mobile: referralObject.mobile,
                Phone: referralObject.phone
            }

            // Update Referral Contact
            notifyPromise(api.post('/microrefer/updatereferralcontacts', JSON.stringify(updateContactDto), {
                headers: {
                    'Content-Type': 'application/json'
                }

            }).then(response => {

                if (response.status === 200 || 201) {

                    if (referralObject.reUploadFile) {
                        const formData = new FormData()
                        formData.append('file', referralObject.reUploadFile)
                        formData.append('id', referralObject.candidateID)

                        notifyPromise(api.post('/microrefer/uploadresumefile', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(fileResponse => {
                            if (fileResponse.status === 200 || 201) {

                                // clear the file container
                                document.getElementById(`fileUpload${referralObject.candidateID}`).value = ''
                                getMyReferralList(selectedPage)

                            } else {
                                console.log(`Error ${fileResponse.status}: ${fileResponse.statusText}`)
                            }
                        }), 'Re-uploading Resume...', 'Upload Successfully', 'Something went wrong')
                    }

                } else {
                    console.log(`Error ${response.status}: ${response.statusText}`)
                }

            }), 'Updating contacts...', 'Update Successfully', 'Something went wrong')
        }
    }

    const validateSaveChanges = (referralObject) => {
        let errors = []

        if (!referralObject.mobile) {
            errors.push('Contact Number is required')
        }

        // if re-upload has value
        if (referralObject.reUploadFile) {
            const fileExts = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

            if (fileExts.filter(ext => referralObject.reUploadFile.type === ext).length === 0) {
                errors.push('Invalid Upload Resume file extension')
            }

            if (referralObject.reUploadFile.name.length > 150) {
                errors.push('File Name exceeds 150 characters')
            }

            if (referralObject.reUploadFile.size > 3000000) {
                errors.push('File exceeds 3MB')
            }
        }

        return errors
    }

    const downloadResume = (candidateId, attachmentId, fileName) => {
        notifyPromise(api.get(`/microrefer/downloadresumefile?id=${candidateId}&attachmentId=${attachmentId}&fileName=${fileName}`, {
            responseType: 'arraybuffer'
        }).then(response => {
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;

                a.download = fileName;
                document.body.appendChild(a);
                a.click();
            } else {
                console.log(`Error ${response.status}: ${response.statusText}`)
            }

        }), 'Downloading Resume...', 'Downloaded Successfully', 'Something went wrong')
    }

    const setApplicationStatus = (application) => {
        let strStatus = '';
        
        if(application.status){
            if(application.status.includes("Non-Rehireable")){
                strStatus = 'Unsuccessful';
            } else {
                strStatus = `${application.status} ${application.statusDescription || ''}`;
            }
        }

        return strStatus;
    }

    useEffect(() => {
        // get hot job list

        api.get('/microrefer/getjobrolespercategory?category=Hot+Jobs&location=&&pageIndex=1&pageSize=10').then(response => {
            setHotJobList(response.data)
        })

    }, [])

    return (
        <main id="my-referrals-page">
            <SectionTitle title="My Referrals" />

            <section id="section-my-referrals">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">

                            <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                                <div className="dropdown-group d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                                    <div className="mr-0 mr-md-3 mb-3 mb-md-0">
                                        <select className="custom-select" onChange={(e) => setStatusFilter(e.target.value)}>
                                            <option value="">Show All</option>
                                            <option value="true">Hired</option>
                                            <option value="false">Referred</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <input type="search" className="form-control" placeholder="Search name" maxLength="50" aria-label="Search name" aria-describedby="basic-search-name"
                                        onChange={(e) => setNameFilter(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                getMyReferralTotalRows()
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            {
                                referralList.length > 0 ? (
                                    <>
                                        <div className="referrals-container my-5">
                                            {
                                                referralList.map((candidate, index1) => {
                                                    return (
                                                        <div key={index1} className={candidate.successfulHire ? "card card-success" : "card card-info"}>
                                                            <div className="card-header">
                                                                <div className="d-flex flex-column flex-sm-row justify-content-between">
                                                                    <div className="d-flex align-items-center mb-3 mb-sm-0">
                                                                        <img src="/refer/images/icon-referral-default.svg" alt="lorem ip sum" />&nbsp;&nbsp;<p className="card-title mb-0 mr-2">{candidate.firstName || candidate.lastName ? `${candidate.firstName} ${candidate.lastName}` : '[Not Available]'}</p>
                                                                    </div>
                                                                    <div>
                                                                        <span className="badge badge-info text-uppercase">{candidate.successfulHire ? 'Hired' : 'Referred'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="card-body">

                                                                <ul className="nav" id="pills-tab" role="tablist">
                                                                    <li className="nav-item">
                                                                        <a className="nav-link text-uppercase active" id={`pills-applications-tab-${index1}`} data-toggle="pill" href={`#pills-applications-${index1}`} role="tab" aria-controls="pills-applications" aria-selected="true">Applications {`(${candidate.referralApplication.length})`}</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link text-uppercase" id={`pills-profile-tab-${index1}`} data-toggle="pill" href={`#pills-profile-${index1}`} role="tab" aria-controls="pills-profile" aria-selected="false">Profile</a>
                                                                    </li>
                                                                </ul>



                                                                <div className="tab-content" id="pills-tabContent">
                                                                    <div className="tab-pane fade show active" id={`pills-applications-${index1}`} role="tabpanel" aria-labelledby="pills-applications-tab">
                                                                        <div className="table-responsive">
                                                                            <table className="table">
                                                                                <thead className="thead-light">
                                                                                    <tr>
                                                                                        <th scope="col" className="text-uppercase">Position</th>
                                                                                        <th scope="col" className="text-uppercase">Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        candidate.referralApplication.map((application, index2) => {
                                                                                            return (
                                                                                                <tr key={index2}>
                                                                                                    <th scope="row">{application.jobTitle}</th>
                                                                                                    <td><strong>{setApplicationStatus(application)}</strong></td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>

                                                                    <div className="tab-pane fade" id={`pills-profile-${index1}`} role="tabpanel" aria-labelledby="pills-profile-tab">

                                                                        <div className="profile-form-container">
                                                                            <form autoComplete="off">
                                                                                <div className="form-row">
                                                                                    <div className="form-group col-md-6">
                                                                                        <label htmlFor="inputEmail">Email</label>
                                                                                        <input type="email" className="form-control" id="inputEmail" readOnly disabled value={candidate.email ? candidate.email : ''} />
                                                                                    </div>
                                                                                    <div className="form-group col-md-6">
                                                                                        <label htmlFor="inputContactNumber">Contact Number</label>
                                                                                        <input type="text" className="form-control" id="inputContactNumber" maxLength="11" placeholder="e.g 0945" value={candidate.mobile ? candidate.mobile : ''} onChange={(e) => {
                                                                                            const currentState = referralList.map(refer => {
                                                                                                if (candidate.candidateID === refer.candidateID) {
                                                                                                    return { ...refer, mobile: e.target.value }
                                                                                                }

                                                                                                return refer
                                                                                            })

                                                                                            setReferralList(currentState)
                                                                                        }}
                                                                                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9.]/g, '')} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-row">
                                                                                    <div className="form-group col-md-6">
                                                                                        <label htmlFor={`fileUpload${candidate.candidateID}`}>Re-Upload Resume</label>
                                                                                        <input type="file" className="form-control form-control-sm" id={`fileUpload${candidate.candidateID}`} size="sm" onChange={(e) => {
                                                                                            const currentState = referralList.map(refer => {
                                                                                                if (candidate.candidateID === refer.candidateID) {
                                                                                                    return { ...refer, reUploadFile: e.target.files[0] }
                                                                                                }

                                                                                                return refer
                                                                                            })

                                                                                            setReferralList(currentState)

                                                                                        }} />
                                                                                        <small id="inputFileHelp" className="form-text text-muted">
                                                                                            <ul style={{ fontSize: '10px' }}>
                                                                                                <li>File Name must below 150 characters</li>
                                                                                                <li>Max File size of 3MB</li>
                                                                                                <li>File must be a PDF or Word</li>
                                                                                            </ul>
                                                                                        </small>
                                                                                        {
                                                                                            candidate.resumeFileName ? (
                                                                                                <button className='btn btn-success' onClick={(e) => {
                                                                                                    e.preventDefault()
                                                                                                    downloadResume(candidate.candidateID, candidate.attachmentId, candidate.resumeFileName)
                                                                                                }}>Download Resume</button>
                                                                                            ) : null
                                                                                        }
                                                                                    </div>
                                                                                    <div className="form-group col-md-6">
                                                                                        <label htmlFor="inputContactNumberOther">Other Contact Number</label>
                                                                                        <input type="tel" className="form-control" id="inputContactNumberOther" maxLength="11" value={candidate.phone ? candidate.phone : ''} onChange={(e) => {
                                                                                            const currentState = referralList.map(refer => {
                                                                                                if (candidate.candidateID === refer.candidateID) {
                                                                                                    return { ...refer, phone: e.target.value }
                                                                                                }

                                                                                                return refer
                                                                                            })

                                                                                            setReferralList(currentState)
                                                                                        }}
                                                                                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9.]/g, '')} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <button className="btn btn-outline button" onClick={(e) => {
                                                                                        e.preventDefault()
                                                                                        updateContacts(candidate)
                                                                                    }}>Save Changes</button>
                                                                                </div>
                                                                            </form>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                        <Paging totalRows={totalRows} changePage={changePage} pageSize={3} />
                                    </>

                                ) : null
                            }

                            {
                                showNoResult ? <><br /><br /><h3>No Results Found</h3></> : null
                            }

                        </div>
                        <div className="col-lg-3">
                            {
                                hotJobList.length > 0 ? (
                                    <>
                                        <h3 className="font-weight-normal mb-4">JOB OPENINGS <br />& REFERRALS</h3>
                                        <div className="job-openings-list mb-5">
                                            <ul>
                                                {
                                                    hotJobList.map((job, index) => {
                                                        return <li key={index}><a href="#" style={{ cursor: 'pointer' }} onClick={() => window.open(`/jobdetails/${job.jobID}`, '_blank')}>{job.jobTitle}</a> <div className="referral-amount">{currencyFormat(job.incentive)}</div></li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </section>

            <QuestionAbout />
        </main>
    )
}

export default MyReferrals