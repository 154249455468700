import FaqAccordion from './FaqAccordion'
import SectionTitle from './SectionTitle'

const Faqs = () => {

    document.title = 'FAQ | MicroRefer'

  return (
    <main>
        <SectionTitle title="Frequently Asked Questions"/>

        <section id="section-faq" style={{paddingBottom: '120px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10">
                        <FaqAccordion/>
                    </div>
                </div>
            </div>
        </section>
    </main>
  )
}

export default Faqs