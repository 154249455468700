const FaqAccordion = () => {
    return (
        <div id="accordion" className="accordion">
            <div className="card">
                <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            What is MicroRefer?
                        </button>
                    </h5>
                </div>
                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                        MicroRefer is a program designed for MicroSourcing Employees to earn EXTRA by referring highly-qualified candidates to any of the open job vacancies.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Who can make a referral?
                        </button>
                    </h5>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div className="card-body">
                        All Microsourcing employees <strong>except the Talent Acquisition team and the Hiring Managers</strong> can participate in MicroRefer programs and individual promos / activities.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Who is not eligible to be referred?
                        </button>
                    </h5>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body">
                        <ul>
                            <li>The applicant/candidate has been tagged in MicroRefer as a referral of another employee within a 3-month period from the date of the last application.</li>
                            <li>The candidate was a former employee of MicroSourcing, regardless of the length of tenure or the employment range with the company.</li>
                            <li>The candidate has already been processed by Talent Acquisition within a 3-month period from the date of the last application.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingFour">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            How can I refer?
                        </button>
                    </h5>
                </div>
                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                    <div className="card-body">
                        <ul>
                            <li><strong>For Microsourcing employees with MicroRefer access</strong>, enter your referral’s information on MicroRefer.</li>
                            <li><strong>For client staff without MicroRefer access</strong>, send an email of your referral to Recruitment Sourcing &lt;sourcing_channel@microsourcing.com&gt;</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingFive">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            How much is the referral incentive?
                        </button>
                    </h5>
                </div>
                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                    <div className="card-body">
                        TA launches multiple Employee Referral Promos every month and referral incentives vary depending on the promo.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingSix">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            How long should the referral stay with Microsourcing to qualify for the MicroRefer incentive?
                        </button>
                    </h5>
                </div>
                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                    <div className="card-body">
                        Qualified referrals are those who are with Microsourcing 100 days after the start date, unless specific promos specify different terms. Please review the mechanics of each referral program.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingSeven">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            When is my referral incentive going to be released?
                        </button>
                    </h5>
                </div>
                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                    <div className="card-body">
                        Incentives – regardless of the amount will be paid in one payout. The payout is 15th and 30th of each month from the start date plus 100 calendar days. The cut-off for processing – 2nd & 10th of each month.
                        <br /><br />
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">START DATE</th>
                                        <th scope="col">100TH DAY AT MS</th>
                                        <th scope="col">APPLICABLE CUT-OFF</th>
                                        <th scope="col">PAYOUT DATE </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Ex. 1</th>
                                        <td>05/05/2024</td>
                                        <td>08/13/2024</td>
                                        <td>2nd</td>
                                        <td>09/15/2024</td>
                                    </tr>
                                    <tr>
                                        <th>Ex. 2</th>
                                        <td>08/03/2024</td>
                                        <td>11/11/2024</td>
                                        <td>2nd</td>
                                        <td>12/15/2024</td>
                                    </tr>
                                    <tr>
                                        <th>Ex. 3</th>
                                        <td>11/12/2025</td>
                                        <td>02/20/2025</td>
                                        <td>2nd</td>
                                        <td>03/15/2025</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingEight">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            Why did I receive only half of the total ERP incentive I was supposed to get?
                        </button>
                    </h5>
                </div>
                <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                    <div className="card-body">
                        Send us the details of your referrals for checking. File a ticket at <a href="https://www.microstatus.com/">www.microstatus.com</a>.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingNine">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            The system is not working and I cannot submit my referrals. What should I do?
                        </button>
                    </h5>
                </div>
                <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                    <div className="card-body">
                        File a ticket via MicroStatus. Choose Talent Acquisition &gt; Referral &gt; Referral Eligibility. Provide your referral’s complete information and attach the MicroRefer’s error message.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingTen">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            Statuses of Referrals
                        </button>
                    </h5>
                </div>
                <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                    <div className="card-body">
                        <strong>Application Review & Good Fit</strong> - Candidate's profile is reviewed and is considered a good fit for the role initially applied for.<br />
                        <strong>Applied</strong> - Initial status. Recently submitted and to be reviewed by the recruiter.<br />
                        <strong>Client Assessment</strong> - A client specific assessment is extended to the candidate to complete and will be reviewed after the successful completion.<br />
                        <strong>Client Endorsement</strong> - The candidate passed the initial stage and will be endorsed to the client for the last stage.<br />
                        <strong>Client Interview 1</strong> - The candidate successfully passed the initial stage of the hiring process and will be endorsed for the local hiring manager’s interview.<br />
                        <strong>Client Interview 2</strong> - The candidate successfully passed the initial stage of the hiring process and will be endorsed for the final client interview.<br />
                        <strong>Internal Endorsement</strong> - This requirement is for internal hiring.<br />
                        <strong>Job Offer Accepted</strong> - The job offer/contract is successfully reviewed and accepted by the candidate.<br />
                        <strong>Job Offered</strong> - The job offer/contract is already extended to the candidate for their review and acceptance.<br />
                        <strong>Left Message to Phone Screen</strong> - Candidate was contacted but to no avail/not available during that time. Message has been sent to check for their availability to take a call.<br />
                        <strong>Other Assessment Passed</strong> - The non-Vervoe / client specific assessment was successfully reviewed and graded.<br />
                        <strong>Other Assessment Pending</strong> - The non-Vervoe / client specific assessment has been extended to the candidate and will be reviewed upon successful completion.<br />
                        <strong>Passive Leads</strong> - Not Interested - Candidate is not interested with the job application/job role. Not actively exploring at the moment.<br />
                        <strong>Phone Screening Passed</strong> - Candidate was contacted and has passed the phone screening process. Will now move forward to the next hiring process.<br />
                        <strong>Placed (CONTRACT)</strong> - Candidate is hired for a contractual or project-based role.<br />
                        <strong>Placed (PERM)</strong> - Candidate is hired for a permanent role.<br />
                        <strong>Pre-Employment Completed</strong> - The candidate has successfully submitted the required/necessary employment and legal documents needed.<br />
                        <strong>Pre-Employment Failed</strong> - The candidate failed to submit all the required/necessary employment and legal documents needed.<br />
                        <strong>Pre-employment Sent</strong> - The candidate has passed all the hiring process and will now be required to submit all the required/necessary employment and legal documents needed.<br />
                        <strong>Progressed by Client</strong> - Client confirmed to move forward interviewing the candidate.<br />
                        <strong>Rejected by Client</strong> - Failed Client Test - The candidate failed the interview/assessment process with the client.<br />
                        <strong>Rejected by Client</strong> - Paper Screening - The candidate did not meet the qualifications required by client.<br />
                        <strong>Unresponsive</strong> - Unresponsive after 3 attempts of requests to do initial phone interview/phone screening<br />
                        <strong>Unsuccessful</strong> - The candidate did not meet the basic qualification for the role he initially applied for.<br />
                        <strong>Unsuccessful - Cultural Fit</strong> - The candidate's background and past job experience/s did not match the background of the role that he specifically applied to.<br />
                        <strong>Unsuccessful - Failed MS Assessment</strong> - The candidate failed to meet the passing score required for the Vervoe assessment.<br />
                        <strong>Unsuccessful - First Level Client Interview</strong> - The candidate failed the client interview.<br />
                        <strong>Unsuccessful - Former Employee (Non-Rehireable)</strong> - The candidate is tagged as non-rehireable.<br />
                        <strong>Unsuccessful - Good candidate</strong> - The candidate has a relevant experience but did not meet the non/negotiable requirement/qualifications of the role that he applied to.<br />
                        <strong>Unsuccessful - High Expected Salary</strong> - The candidate is fit for the job but does not agree with the salary rate.<br />
                        <strong>Unsuccessful - Shift Preference</strong> - The candidate is not amenable to work in the working shift of the role.<br />
                        <strong>Unsuccessful - Skills</strong> - The candidate's skills failed to match the requirements and qualification of the role.<br />
                        <strong>Vervoe Assessment Passed</strong> - The Vervoe assessment result was successfully reviewed and graded.<br />
                        <strong>Vervoe Assessment Pending</strong> - The Vervoe assessment has been extended to the candidate and will be reviewed after the completion of it.<br />
                        <strong>Withdrew application</strong> - The candidate has withdrawn his/her application.<br />
                        <strong>Withdrew Application - Location/Proximity</strong> - The candidate has withdrawn his/her application due to location.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingEleven">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                            Where do I see the status of my referral/s?
                        </button>
                    </h5>
                </div>
                <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordion">
                    <div className="card-body">
                        <ul>
                            <li>If you referred via MicroRefer.com, you can see your referral's status by going to the My Referral tab.</li>
                            <li>For referrals referred outside MicroRefer, the Sourcing team will send an email regarding your referral’s status every Friday of the week.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingTwelve">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                            Why am I not seeing the status of my referrals?
                        </button>
                    </h5>
                </div>
                <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#accordion">
                    <div className="card-body">
                        <ul>
                            <li>Do basic troubleshooting. Refresh the page and try to login and logout.</li>
                            <li>Try accessing MicroRefer website by using different search engine and hardware</li>
                            <li>If the status is still not appearing on the website, you may file a ticket via www.microstatus.com.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingThirteen">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                            Upon submitting my referral/s’ CV, it says “Email already exists in MicroResumes database”.
                        </button>
                    </h5>
                </div>
                <div id="collapseThirteen" className="collapse" aria-labelledby="headingThirteen" data-parent="#accordion">
                    <div className="card-body">
                        “Email already exists in MicroResumes database” means that the person you are referring to is already a candidate in the database. Existing candidates cannot be tagged as referrals.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingFourteen">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                            The role where I first referred my friend has been closed. Can I re-refer my friend to another role?
                        </button>
                    </h5>
                </div>
                <div id="collapseFourteen" className="collapse" aria-labelledby="headingFourteen" data-parent="#accordion">
                    <div className="card-body">
                        You can only refer a candidate once. If the role where you referred him/her is no longer active or the candidate status is not progressing, file a ticket via MicroStatus. Include your Full Name, EID and the Name, Contact Number and Email Address of your referral.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingFifteen">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFourteen">
                            I have mistakenly typed my referral’s name, can I change/delete it?
                        </button>
                    </h5>
                </div>
                <div id="collapseFifteen" className="collapse" aria-labelledby="headingFifteen" data-parent="#accordion">
                    <div className="card-body">
                        To maintain the integrity of referral data, you can no longer edit or delete your referral’s information once it has been submitted. Make sure you enter the right email ad of your referral.
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header" id="headingSixteen">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsgSixteen" aria-expanded="false" aria-controls="collapsegSixteen">
                            I have a suggestion to improve the MicroRefer Program. Whom should I contact?
                        </button>
                    </h5>
                </div>
                <div id="collapsgSixteen" className="collapse" aria-labelledby="headingSixteen" data-parent="#accordion">
                    <div className="card-body">
                        Please send us a message here &lt;sourcing_channel@microsourcing.com&gt;
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FaqAccordion