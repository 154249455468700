import useCurrency from '../hooks/useCurrency'
import dayjs from 'dayjs'
import { useContext } from 'react'
import { AuthContext } from '../context/AuthProvider'

export const JobDetailsTitle = ({jobDetails}) => {

    const { authUser } = useContext(AuthContext)

    const currencyFormat = useCurrency()

    return (
        Object.keys(jobDetails).length > 0 ? (
            <section className="section-banner section-inner-banner">
            <div className="inner-banner d-flex justify-content-center align-items-center">
                <div className="container py-0">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="inner-banner d-flex justify-content-center flex-column">
                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                    <h1 className="mr-4 mb-3 mb-md-0">{jobDetails.jobName}</h1>
                                    {
                                        Object.keys(authUser).length > 0 ? (
                                            <div className="mb-3 mb-md-0">
                                                <span className="badge badge-success text-uppercase">Get {currencyFormat(jobDetails.incentive)}</span>
                                            </div>
                                        ) : (
                                            <div className="mb-3 mb-md-0">
                                                <span className="badge badge-success text-uppercase">Get Referral Bonus</span>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="job-meta-container">
                                    <p className="job-id mb-0">JOB ID: {jobDetails.id}</p>
                                    <div>
                                        <ul className="nav">
                                            <li><img src="/refer/images/icon-clock.svg" alt="lorem ip sum" className="mr-1"/>&nbsp;{jobDetails.createDate && dayjs(jobDetails.createDate).format('MMMM DD, YYYY')}</li>
                                            <li><img src="/refer/images/icon-map-marker-alt.svg" alt="lorem ip sum" className="mr-1"/>&nbsp;{jobDetails.location}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        ) : null
    )
}

const SectionTitle = ({title}) => {
  return (
    <section className="section-banner section-inner-banner">
        <div className="inner-banner d-flex justify-content-between align-items-center">
            <div className="container p-0">
                <div className="row no-gutters">
                    <div className="col-12">
                        <h1>{title}</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default SectionTitle