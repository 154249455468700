import JobCategories from '../components/Home/JobCategories'
import JobRoles from '../components/Home/JobRoles'
import { HomeSlider } from '../components/Carousel/Slider'
import QuestionAbout from './QuestionAbout'
import { useEffect, useState } from 'react'
import useAxios from '../hooks/useAxios'

const Home = () => {

  document.title = 'Home | MicroRefer'

  const [carousel, setCarousel] = useState([])
  const api = useAxios()

  useEffect(() => {
    const getBannerListTotalRows = async () => {
      const responseCarouselList = await api.get('/microrefer/getcarouselbanner')
      setCarousel(responseCarouselList.data)
    }

    getBannerListTotalRows()

  }, [])

  return (
    <main id="referral-page">
        <HomeSlider slideList={carousel}/>

        <div className="paging-info"></div>

        <section id="section-career">
          <div className="container">
            <div>
                <h6 className="section-sub-title">Opportunities</h6>
                <h2 className="section-title">Job Openings & Referral Incentives</h2>
                <p>Join our recruitment referral program and earn rewards for your referrals.</p>
            </div>
            <div className="row mt-5">
                <JobCategories/>
            </div>
          </div>
        </section>
        <QuestionAbout/>
    </main>
  )
}

export default Home