import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import dayjs from 'dayjs'
import { useContext } from 'react'
import { AuthContext } from '../context/AuthProvider'
import useToastNotification from './useToastNotification'

const baseURL = process.env.REACT_APP_BASE_URL

const useAxios = () => {
    const {authUser, setAuthTokens, logout} = useContext(AuthContext)
    const {notifyError, notifyWarning} = useToastNotification()

    const axiosInstance = axios.create({
        baseURL
    });

    axiosInstance.interceptors.request.use(async config => {

        const lsToken = localStorage.getItem('referAuth') ? JSON.parse(localStorage.getItem('referAuth')).authToken : {}

        const isExpired = dayjs.unix(lsToken.token_expires).diff(dayjs()) < 1


        if (Object.keys(lsToken).length > 0) {
            // if not expired
            if(!isExpired) {            
                config.headers.Authorization = `Bearer ${lsToken.access_token}`
                return config    
            }
            else {
                notifyWarning('Token expired, Please login again')
                logout()    
            }            
        }
        else {
            logout()
            
        }

        // } else {
        // // get new token
        
        //     try {
        //         const urlencoded = new URLSearchParams();
        //         urlencoded.append('grant_type', 'refresh_token');
        //         urlencoded.append('refresh_token', lsToken.refresh_token);
    
        //         const refreshTokenResponse = await fetch(`${baseURL}/token`, {
        //             method:'POST',
        //             headers:{
        //                 'Authorization': `Bearer ${lsToken.access_token}`,
        //                 'Content-Type':'application/x-www-form-urlencoded'
        //             },
        //             body: urlencoded
        //         })

        //         const refreshTokenData = await refreshTokenResponse.json()

        //         if(refreshTokenResponse.status === 200) {

        //             const storeLSData = {
        //                 authToken: refreshTokenData,
        //                 authUser: authUser
        //             }
        
        //             localStorage.setItem('referAuth', JSON.stringify(storeLSData))
        
        //             setAuthTokens(refreshTokenData)
        
        //             config.headers.Authorization = `Bearer ${refreshTokenData.access_token}`
        
        //         } else {
        //             const errMsg = `Error ${refreshTokenResponse.status}: ${refreshTokenData.error}`
        //             throw errMsg
        //         }
    
        //     } catch (err) {
        //         /**
        //          * - This Error catching will only be triggered once the refresh token has already been used
        //          * - It is impossible unless the developer do some bug tracing and used the refresh token
        //          * 
        //          * */

        //         console.error('Refresh Token Error: invalid_grant, the refresh token has already been used')
        //         notifyError(err)
        //         notifyWarning('Token expired, Please login again')
        //         logout()
        //     }        
            
        //     return config
        // }
    })
    
    loadProgressBar({}, axiosInstance)
    
    return axiosInstance
}

export default useAxios