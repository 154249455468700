import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../context/AuthProvider'
import useToastNotification from '../../hooks/useToastNotification'

const Login = () => {
  const [eid, setEid] = useState('')
  const [pwd, setPwd] = useState('')

  const [strictInt, setStrictInt] = useState('')
  
  const { login, disableSubmit } = useContext(AuthContext)
  const { notifyError } = useToastNotification()

  const submitLogin = (e) => {
    e.preventDefault()

    if (eid && pwd) {
      login({eid, pwd})
      
    } else {
      notifyError('The Username or Password is incorrect')
    }
  }

  return (
    <form>
        <div className="form-group">
            <label htmlFor="employeeId">Employee ID</label>
            <input type="text" className="form-control" id="employeeId" placeholder="EID"
              autoComplete="off"
              maxLength={10}
              value={eid} 
              onChange={(e) => setEid(e.target.value)} 
              disabled={disableSubmit}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '')

              }}
            />
        </div>
        <div className="form-group mb-0">
            <label htmlFor="employeePassword">Password</label>
            <input type="password" className="form-control" id="employeePassword" placeholder="Password"
              maxLength={50}
              value={pwd} 
              onChange={(e) => setPwd(e.target.value)} 
              disabled={disableSubmit}
            />
        </div>
        <div className="form-group">
            <a href="https://www.microstatus.com/employees/emphome/passwordresetoption/" target="_blank">Forgot your password?</a>
        </div>
        <button type="submit" className="btn btn-primary button" disabled={disableSubmit} onClick={(e) => submitLogin(e)}>Log in</button>
    </form>
  )
}

export default Login