import ReactPaginate from 'react-paginate'

const Paging = ({ totalRows, changePage, pageSize } ) => {

  const pageCount = Math.ceil(totalRows / pageSize);

  return (
    <>
      <nav aria-label="Page navigation example">
        <ReactPaginate
          hrefAllControls={true}
          previousLabel={'Previous'}
          nextLabel={'Next'}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={'pagination justify-content-end pb-5'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          disabledClassName={'disabled'}
          activeClassName={'active'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
        />
      </nav>
    </>
  )
}

export default Paging