import useCurrency from '../../hooks/useCurrency'
import Paging from '../Paging'

const JobRoles = ({roleList, locationList, setSelectedLocation, selectedCategory, selectedCategoryJobCount, changePage, pageSize}) => {

    const currencyFormat = useCurrency()

  return (
    <div className="col-lg-8 pl-lg-5 mt-5 mt-lg-0">
        <div className="row">
            <div className="offset-lg-6">
                <div style={{marginTop:'12px', marginRight: '10px'}}>
                    <h6><img src="/refer/images/icon-map-marker-alt.svg" alt="lorem ip sum" className="mr-1"/>Job Location: </h6>
                </div>
            </div>
            <div>
                <select className="custom-select" onChange={(e) => setSelectedLocation(e.target.value)}>
                    <option key={0} value="">Show All</option>
                    {
                    locationList.map((loc, index) => <option key={index + 1} value={loc}>{loc}</option>)
                    }
                </select>
            </div>
        </div>
        <h6 className="mb-4">OPENING ROLES</h6>
        <div className="opening-roles-container">
            {
                roleList.length > 0 ? (
                    <>
                        {
                            roleList.map((role,index) => {
                                return (
                                    <div className="role" key={index}>
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <h4 style={{cursor:'pointer'}} onClick={()=> window.open(`/jobdetails/${role.jobID}`,'_blank')}>{role.jobTitle}</h4>
                                                <p><i className="fas fa-map-marker-alt mr-1"></i>{role.location} | {role.company}</p>
                                            </div>
                                            <div className="col-sm-4">
                                                <p className="role-incentive"><span style={{fontWeight: 700, color: '#dc7227'}}>Bonus</span> - {currencyFormat(role.incentive)}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <br/>
                        <Paging totalRows={selectedCategoryJobCount} changePage={changePage} pageSize={pageSize}/>
                    </>
                ) : (
                    <div className="row">
                        <div className="col-sm-8">
                            <h4>No {selectedCategory} Roles Found</h4>
                        </div>
                    </div>
                )
            }      
        </div>
    </div>
  )
}

export default JobRoles