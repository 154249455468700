const NotFound = () => {
    return (
      <main id="home">
          <section className="section-banner">
              <div className="container">
                  <div className="row">
                      <div className="col-md-7">
                          <h1 className="mb-5">Error 404: Page Not Found</h1>
                      </div>
                  </div>
              </div>
          </section>
      </main>
    )
  }
  
  
  export default NotFound