import { createContext } from 'react'
import { ToastContainer, toast } from 'react-toastify'


export const ToastContext = createContext()

export const ToastProvider = ({ children }) => {

    const toastSettings = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        limit: 5,
    }

    const notifySuccess = (message) => {
        toast.success(message, toastSettings)
    }

    const notifyWarning = (message) => {
        toast.warn(message, toastSettings)
    }

    const notifyError = (message) => {
        toast.error(message, toastSettings)
    }

    const notifyPromise = (promise, pending, success, error) => {
        return toast.promise(
            promise,
            {
                pending: pending,
                success: success,
                error: error
            }
        )
    }

    const contextData = {
        notifySuccess,
        notifyWarning,
        notifyError,
        notifyPromise
    }

    return (
        <ToastContext.Provider value={contextData}>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={5}
                style={{fontSize: '14px'}}
            />
            { children }
        </ToastContext.Provider>
    )
}