import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Container, Button, Row, Col, Form, Modal } from 'react-bootstrap'
import useAxios from '../../hooks/useAxios'
import useToastNotification from '../../hooks/useToastNotification'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';

const AdminModal = ({crudState, showModal, handleClose, addBanner, updateBanner, deleteBanner, selectedBannerId, setUpdatedTheListCount}) => {

    const api = useAxios()

    const { notifyPromise, notifySuccess, notifyError } = useToastNotification()

    const [crudBannerName, setCrudBannerName] = useState('')
    const [crudStartDate, setCrudStartDate] = useState('')
    const [crudEndDate, setCrudEndDate] = useState('')
    const [crudBannerUrl, setCrudBannerUrl] = useState('')
    const [crudFileName, setCrudFileName] = useState('')
    const [fileNamePreview, setFileNamePreview] = useState('')

    const [crudFile, setCrudFile] = useState('')

    const onSubmit = (e) => {

        e.preventDefault()

        const errorMessages = validateFields()

        if (errorMessages.length > 0) {
            errorMessages.forEach(err => notifyError(err))           

        } else {

            if(crudState === 'Add') {
                notifyPromise(addBanner({ crudBannerName, crudStartDate, crudEndDate, crudBannerUrl, crudFile }).then(response => {
                    if (response.status === 200 || 201) {
                        handleClose()
                        setUpdatedTheListCount(prev => prev + 1)
                    } else {
                        console.log(`Error ${response.status}: ${response.statusText}`)
                    }

                }), 'Adding Banner...', 'Add Banner Successfully', 'Something went wrong')

            } else {
                notifyPromise(updateBanner({ selectedBannerId, crudBannerName, crudStartDate, crudEndDate, crudBannerUrl, crudFileName }).then(response => {
                    if (response.status === 200) {
                        handleClose()
                        setUpdatedTheListCount(prev => prev + 1)
                    } else {
                        console.log(`Error ${response.status}: ${response.statusText}`)
                    }
                }), 'Updating Banner...', 'Update Banner Successfully', 'Something went wrong')
            }
            
            
        }
    }

    const handleDelete = (e) => {
        
        e.preventDefault()

        notifyPromise(deleteBanner().then(response => {
            if (response.status === 200) {
                handleClose()
                setUpdatedTheListCount(prev => prev + 1)
            } else {
                console.log(`Error ${response.status}: ${response.statusText}`)
            }
        }), 'Deleting Banner...', 'Delete Banner Successfully', 'Something went wrong')
    }

    const validateFields = () => {
        let errors = [];

        if (!crudBannerName) {
            errors.push('Banner Name is required')
        }

        if (!crudStartDate) {
            errors.push('Start Date is required')
        }

        if (!crudEndDate) {
            errors.push('End Date is required')
        }

        if (crudStartDate && crudEndDate) {
            const isValid = dayjs(crudEndDate).diff(crudStartDate) >= 0;

            if (!isValid) {
                errors.push('End Date must be higher than Start Date')
            }
        }
        
        if (crudState === 'Add') {
            if (!crudFile) {
                errors.push('File Upload is required')
    
            } else {
                const fileExts = ['image/png', 'image/jpeg', 'image/gif']
    
                if (fileExts.filter(ext => crudFile.type === ext).length === 0) {
                    errors.push('Invalid File Extension')
                }
    
                if (crudFile.name.length > 150) {
                    errors.push('File Name exceeds 150 characters')
                }
    
                if (crudFile.size > 3000000) {
                    errors.push('File exceeds 3MB')
                }
            }    
        }
        
        return errors
    }

    useEffect(() => {
        if (!showModal) {
            // Clear fields in modal
            setCrudBannerName('')
            setCrudStartDate('')
            setCrudEndDate('')
            setCrudBannerUrl('')
            setCrudFileName('')
            setFileNamePreview('')

            setCrudFile('')            
        }

    }, [showModal])

    // fetch banner details in api for update modal
    useEffect(() => {
        if (crudState === 'Edit' && selectedBannerId > 0) {
            notifyPromise(api.get(`/microrefer/getmicroreferbannerdetails/${selectedBannerId}`).then(response => {
                
                setCrudBannerName(prev => prev = response.data.bannerName)
                setCrudStartDate(prev => prev = new Date(response.data.startDate))
                setCrudEndDate(prev => prev = new Date(response.data.endDate))
                setCrudBannerUrl(prev => prev = response.data.url)
                setCrudFileName(prev => prev = response.data.fileName)
                setFileNamePreview(prev => prev = `${process.env.REACT_APP_BASE_URL}/MicroReferBanner/${response.data.fileName}`)

            }), 'Fetching Banner Details...')
        }

    }, [selectedBannerId])
    
  return (
    <Modal show={showModal} onHide={handleClose} centered={true}>
        <Form autoComplete="off">
            <Modal.Header>
                <Modal.Title><h3>{crudState} Banner</h3></Modal.Title>
                <Button variant="outlined" onClick={handleClose}><h3>&times;</h3></Button>
            </Modal.Header>
            <Modal.Body>
                <Container>                    
                    <Form.Group as={Row} className="mb-3" controlId="bannerName">
                        <Form.Label column sm={3}>
                            Banner Name
                        </Form.Label>
                        <Col>
                            <Form.Control type="text" name="bannerName" value={crudBannerName} maxLength="50" onChange={(e) => setCrudBannerName(e.target.value)}/>
                        </Col>
                    </Form.Group>
                    <Row className="mb-3">
                        <Col md={3}>
                            <Form.Label htmlFor="startDate">
                                Start Date:
                            </Form.Label>
                        </Col>
                        <Col>
                            <DatePicker 
                                id="startDate" 
                                className="form-control datepicker" 
                                dateFormat="MM/dd/yyyy" 
                                placeholderText="mm/dd/yyyy" 
                                selected={crudStartDate}
                                onChange={(date) => setCrudStartDate(date)}
                                onChangeRaw={(e) => e.target.value = ''}
                                showYearDropdown
                                scrollableYearDropdown
                            />
                            {/* <Form.Control size="sm" type="date" id="startDate" name="startDate" value={crudStartDate} onChange={(e) => setCrudStartDate(e.target.value)}/> */}
                        </Col>
                        <Col>
                            <Form.Label htmlFor="endDate">
                                End Date:
                            </Form.Label>
                        </Col>
                        <Col>
                        <DatePicker 
                            id="endDate" 
                            className="form-control datepicker" 
                            dateFormat="MM/dd/yyyy" 
                            placeholderText="mm/dd/yyyy" 
                            selected={crudEndDate} 
                            onChange={(date) => setCrudEndDate(date)}
                            onChangeRaw={(e) => e.target.value = ''}
                            showYearDropdown
                            scrollableYearDropdown
                        />
                            {/* <Form.Control size="sm" type="date" id="startDate" name="startDate" value={crudStartDate} onChange={(e) => setCrudStartDate(e.target.value)}/> */}
                        </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-3" controlId="bannerLink">
                        <Form.Label column sm={3}>
                            Banner Link:
                        </Form.Label>
                        <Col>
                            <Form.Control type="text" name="bannerLink" value={crudBannerUrl} maxLength="50" onChange={(e) => setCrudBannerUrl(e.target.value)}/>
                            <small id="inputBannerLinkHelp" className="form-text text-muted">Enter an absolute URL example: https://www.facebook.com/MicroSourcing</small>
                        </Col>
                    </Form.Group>
                    {
                        crudState === 'Add' ? (
                            <Row className="mb-3">
                                <Col className="offset-md-3">
                                    <Form.Control type="file" size="sm" name="fileName" onChange={(e) => setCrudFile(e.target.files[0])}/>
                                </Col>
                            </Row>
                        ) : null
                    }

                    <Row className="mb-5">
                        {
                            crudState === 'Edit' ? (
                                <Col className="offset-md-3">
                                    <img src={fileNamePreview} style={{width:240, height:148, objectFit: 'cover'}} className="img-fluid" alt="Preview" title="Preview" />
                                </Col>
                            ) : (
                                <Col className="offset-md-3">
                                    <small id="inputFileHelp" className="form-text text-muted">
                                        <ul style={{paddingLeft: '15px'}}>
                                            <li>Image size of 920 by 550 pixels</li>
                                            <li>File Name must below 150 characters.</li>
                                            <li>Max File size of 3MB</li>
                                            <li>File type JPG, PNG or GIF</li>
                                        </ul>
                                    </small>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {crudState === 'Edit' ? <button className="btn btn-danger" onClick={(e) => handleDelete(e)}>Delete</button> : null}
                <button className="btn btn-success" onClick={(e) => onSubmit(e)}>Save Changes</button>
            </Modal.Footer>
        </Form>
  </Modal>
  )
}

export default AdminModal