import { useState, useEffect } from 'react'
import useAxios from '../../hooks/useAxios'
import JobRoles from './JobRoles'


const JobCategories = () => {
  const api = useAxios()
  const [categoryList, setCategoryList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('Hot Jobs')
  const [selectedCategoryJobCount, setSelectedCategoryJobCount] = useState(0)
  const [selectedLocation, setSelectedLocation] = useState('')
  const [jobRoles, setJobRoles] = useState([])

  // First load
  useEffect(() => {
    getLocationFilter()    

  }, [])

  // First load and on change of selected location 
  useEffect(() => {
    getCategoryList()  

  }, [selectedLocation])

  // First load and on change of selected category/location
  useEffect(() => {

    // Passed 1 value for initialization of paging or changing of selected category
    getJobRolesPerCategory(1)

  }, [selectedCategory, selectedLocation])
  
  // Get category listing
  const getCategoryList = async () => {
    const responseCategoryList = await api.get('/microrefer/getjobopeningscategory', { params: { location: selectedLocation } })
    setCategoryList(responseCategoryList.data)

    // Get the first element in the category list then get number of Jobs for total rows paging
    setSelectedCategory(responseCategoryList.data[0].categoryName)
    setSelectedCategoryJobCount(responseCategoryList.data[0].numberOfJobs)
  }

  // Get location filter
  const getLocationFilter = async () => {
    const responseLocationList = await api.get('/microrefer/getlocation')
    setLocationList(responseLocationList.data)
  }

  // Get job roles per category
  const getJobRolesPerCategory = async (currentPage) => {

    const responseJobRoles = await api.get('/microrefer/getjobrolespercategory', { params: { 
      category: selectedCategory,
      location: selectedLocation,
      pageIndex: currentPage,
      pageSize: 6
    
    } })

    setJobRoles((prev) => prev = responseJobRoles.data)

  }

  // Page click
  const changePage = ({ selected }) => {
    const currentPage = selected + 1
    
    getJobRolesPerCategory(currentPage)
  }

  return (
    <>
      <div className="col-lg-4">
          <h6 className="mb-4">CATEGORIES</h6>
          {
            categoryList.length > 0 ? (
              <ul className="job-categories">
                {
                  categoryList.map((category, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          setSelectedCategory(category.categoryName)
                          setSelectedCategoryJobCount(category.numberOfJobs)
                        }} 
                        className={selectedCategory === category.categoryName ? 'active': null}
                      >
                        {category.categoryName}{' '}{category.numberOfJobs !== 0 && `(${category.numberOfJobs})`}
                      </li>
                    )
                  })}
              </ul>
            ) : null
          }
      </div>
      <JobRoles 
        roleList={jobRoles} 
        locationList={locationList} 
        setSelectedLocation={setSelectedLocation}
        selectedCategory={selectedCategory}
        selectedCategoryJobCount={selectedCategoryJobCount}
        changePage={changePage}
        pageSize={6}
      />
    </>
  )
}

export default JobCategories