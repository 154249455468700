import { useNavigate } from 'react-router-dom'

const QuestionAbout = () => {

  const navigate = useNavigate();

  return (
    <section id="section-questions" className="bg-blue">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <img src="/refer/images/icon-question.png" alt="Question" title="Question" className="mb-3" />
                    <h3 className="section-title text-center">Questions about MicroRefer?</h3>
                    <p>Learn more about the process on our <a style={{cursor:'pointer'}} onClick={() => {navigate('/faq')}}>frequently asked questions page</a>.</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default QuestionAbout