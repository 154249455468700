import $ from 'jquery'

const Footer = () => {

    const animateBackToTop = () => {
        $('body,html').animate({
            scrollTop: 0
        }, 400);

        return false;
    }

  return (
    <footer className="page-footer">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <ul className="list-inline subsidiaries">
                        <li className="list-inline-item">
                            <a href="https://www.microsourcing.com/" target="_blank" rel="noopener noreferrer">
                                <div className="footer-logos" id="footer-logo-microsourcing">
                                    <img src="/refer/images/mic_MS_MicroResumes_logo-footer.png" alt="MicroSourcing" title="MicroSourcing" />
                                </div>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.probegroup.com.au/" target="_blank" rel="noopener noreferrer">
                                <div className="footer-logos" id="footer-logo-probe">
                                    <img src="/refer/images/mic_MS_Probe-logo.png" alt="Probe" title="Probe" />
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-4 text-right">
                    <ul className="list-inline inline-list social-media-container">
                        <li className="list-inline-item"><a target="_blank" title="Facebook" href="https://www.facebook.com/MicroSourcingCareers/" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
                        <li className="list-inline-item"><a target="_blank" title="Instagram" href="https://www.instagram.com/microsourcing/" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
                        <li className="list-inline-item"><a target="_blank" title="LinkedIn" href="https://www.linkedin.com/company/microsourcing" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
                        <li className="list-inline-item"><a target="_blank" title="YouTube" href="https://www.youtube.com/user/Microsourcing" rel="noopener noreferrer"><i className="fa fa-youtube-play"></i></a></li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-md-9">
                    <p>MicroSourcing is a proudly owned subsidiary of Probe Group. &copy; Copyright 2006 - 2021 MicroSourcing International Ltd.. All Rights Reserved</p>
                </div>
                <div className="col-md-3 text-right">
                    <ul className="list-inline footer-menu">
                        <li className="list-inline-item"><a href="#!">Terms and Conditions</a></li>
                        <li className="list-inline-item"><a href="#!">Privacy Notice</a></li>
                    </ul>
                </div>
            </div>
            <a style={{cursor:'pointer'}} className="back_to_top" title="Back To Top" onClick={animateBackToTop}>
                <img src="/refer/images/mic_MS-icon-totop.png" alt="to top" />
            </a>
        </div>
    </footer>
  )
}

export default Footer