import { useState, useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AuthContext } from '../context/AuthProvider'

const Navbar = () => {

    // This is will be used for redirecting to Job Details page again 
    //if the user clicked the login button then successfully logging in
    const location = useLocation()

    const { authUser, logout } = useContext(AuthContext)

    const [hasLogin, setHasLogin] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    
    const submitLogout = () => {
        logout()
    }

    useEffect(() => {
        
        if(Object.keys(authUser).length > 0) {
            setHasLogin(() => true)
        } else {
            setHasLogin(() => false)
        }

    }, [authUser, hasLogin])

    useEffect(() => {

        if (hasLogin) {
            setIsAdmin(authUser.roles.filter((role) => role === 'MicroReferAdmin').length === 1)
        }

    }, [hasLogin])

    // This will check if the current location is JobDetails page
    const isJobDetailsCurrentLocation = () => {
        const pathNameToArray = location.pathname.split('/')
        
        if (pathNameToArray[1] === 'jobdetails') {
            return true
        } else {
            return false
        }
    }

    return (
        <header>
            <nav 
                style={hasLogin || isJobDetailsCurrentLocation() ? null : { position: 'absolute', top: 0} }
                className={ hasLogin || isJobDetailsCurrentLocation() ? 'navbar navbar-expand-lg fixed-top navbar-light bg-white static-top' //login
                            : 'navbar navbar-expand-lg fixed-top navbar-light static-top' //logout
                        }>

                <div className="container-fluid">
                    <div className="navbar-brand-container">
                        <Link className="navbar-brand" to="/Home">
                            {
                                // If has login or if current browser location is in JobDetails = use black logo
                                // Else, use white logo
                                hasLogin || isJobDetailsCurrentLocation() ? <img src="/refer/images/microrefer-alt.png" alt="MicroRefer" title="MicroRefer" />
                                : <img src="/refer/images/microrefer.png" alt="MicroRefer" title="MicroRefer" />
                            }
                        </Link>
                    </div>
                    
                    <button className="navbar-toggler" 
                        type="button" data-toggle="collapse" 
                        data-target="#navbarResponsive" aria-controls="navbarResponsive" 
                        aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                    </button>

                    {
                        hasLogin ? 
                            <div className="collapse navbar-collapse" id="navbarResponsive">
                                <div className="ml-auto d-inline-flex">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/Home">Job Openings</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/MyReferrals">My Referrals</Link>
                                        </li>
                                        <li className="nav-item active">
                                            <Link className="nav-link" to="/Faq">FAQ</Link>
                                        </li>
                                        {
                                            isAdmin ? (
                                                <>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="/HotJobs">Hot Jobs</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to="/Admin">Admin</Link>
                                                    </li>
                                                </>
                                            ) : null
                                        }
                                        <li className="nav-item">
                                            <a className="nav-link button" style={{cursor: 'pointer'}} onClick={submitLogout}>Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        : isJobDetailsCurrentLocation() ? (
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link button" to="/" state={{path: location.pathname}}>Login</Link>
                                </li>
                            </ul>
                        ) : null
                    }
                </div>
            </nav>
        </header>
    )
}

export default Navbar