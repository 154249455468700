import { useState, useEffect } from 'react'

const JobDescription = ({jobDetails}) => {

    const convertStringToHTML = str => {
        let parser = new DOMParser();
        let doc = parser.parseFromString(str, 'text/html');

        return doc.body;
    }

    useEffect(() => {
        if (Object.keys(jobDetails).length > 0) {
            const keySkills = convertStringToHTML(jobDetails.keySkills)
            const requirements = convertStringToHTML(jobDetails.requirements)
            const description = convertStringToHTML(jobDetails.description)

            document.getElementById('jobDetailsLayer1').after(keySkills)
            document.getElementById('jobDetailsLayer2').after(requirements)
            document.getElementById('jobDetailsLayer3').after(description)
        }

    }, [jobDetails])

  return (
    <div className="job-container">
        {Object.keys(jobDetails).length > 0 ? (
            <>
                <h2 id="jobDetailsLayer1">Key Skills</h2>
                <h2 id="jobDetailsLayer2">Role Overview</h2>
                <h2 id="jobDetailsLayer3">Requirements</h2>
            </>
        ) : null}
    </div>
  )
}

export default JobDescription